<template>
	<main>
		<HeaderTab :title="componentTitle" />
        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
                            <CustomTable 
								:id_table="table_id"
								primaryKey="actestype_id"
                                :items="items"
                                :busy.sync="table_busy"
                            />

							<b-modal ref="modal" hide-footer>
								<template v-slot:modal-title>
									<template>
										{{ $t("acte_type.edit_acte_type") }}
									</template>
								</template>

								<div class="col-12">
									<div class="form-group">
										<label for="visibility" class="col-form-label">{{ $t("acte_type.visibility") }}</label>
										<b-form-checkbox
											id="visibility"
											v-model="actestypes_hidden"
											unchecked-value="false"
										>
										{{ $t("acte_type.invisible") }}
										</b-form-checkbox>
									</div>
								</div>

								<b-button class="mt-3 btn-block rounded-pill" block @click.prevent="checkForm" variant="primary">{{ $t("global.modifier") }}</b-button>
							</b-modal>
                        </div>
                    </div>
                </div>
		    </div>
		</div>
	</main>
</template>


<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import ActeType from '@/mixins/ActeType.js'
    import Actes from '@/mixins/Actes.js'

	export default {
		name: "ActeTypeClientArea",
		mixins: [Navigation, Actes, ActeType],
		data () {
			return {
				componentTitle: this.getTrad('acte_type.gestion_acte_type_ca'),
                items: null,
				table_busy: true,
				actestypes_ids: [],
				actestypes_hidden: [],
				
				events_tab: {
                    'TableAction::goToEditActeTypeClientArea': this.edit
                }
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.items = null
				const acte_types_res = (await this.getActesTypesDetails())
					.filter(res => res.active === true)

				let blacklisted_actes_types = []
				blacklisted_actes_types = await this.getBlackListedActesTypes()

                const acte_types = acte_types_res.map((el) => {
					let acte = Object.assign({}, el)
					acte.displayed = blacklisted_actes_types.includes(acte.actestype_id) ? this.getTrad('global.non') : this.getTrad('global.oui')
					acte.displayed_bool = !blacklisted_actes_types.includes(acte.actestype_id)
                    acte.id = acte.actestype_id
                    return acte
                })

                this.items = this.formatDataActeType(acte_types)
				this.table_busy = false
			},

            formatDataActeType(items) {
                items.forEach(item => {
                    item.id = item.actestype_id
                    item.isActive = false
                })

                return items
			},
			
			async edit(actestypes) {
				this.actestypes_ids = actestypes.map(at => at.id)
				this.actestypes_hidden = actestypes.map(at => at.displayed_bool).indexOf(true) === -1
                this.$refs.modal.show()
			},
			
			async checkForm() {
				if (this.actestypes_hidden === true) {
					this.addBlackListedActesTypes(this.actestypes_ids)
				}
				else {
					this.deleteBlackListedActesTypes(this.actestypes_ids)
				}

				this.actestypes_hidden = false
				this.$refs.modal.hide()
				this.table_busy = true
                this.init_component()
            },
		},
		components: {
			HeaderTab: () => import('@/components/HeaderTab'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            InfoAlert: () => import('GroomyRoot/components/Alert/InfoAlert'),
            ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm'),
		},
		computed: {
            table_id() {
                return 'acte_type_ca'
            }
		}
	}
</script>
